import React, { useContext, useRef } from 'react';

// types
import type { SinglePageHeroVariantProps } from '~/components/publicWebsiteV2/common/SinglePageHeroContainer/types';

// context
import { DeviceContext } from '~/contexts/DeviceContext';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

// utils
import { getWebsitePlaceholderAsset } from '~/components/manage/EditWebsite/common/HeroCustomizer/utils';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

// styles
import { BaseHero } from '~/components/publicWebsiteV2/common/SinglePageHeroContainer/SinglePageHeroContainer.styles';
import { HeroSized } from './SinglePageHeroSingleImage.styles';

const SinglePageHeroSingleImage: React.FC<SinglePageHeroVariantProps> = ({
  children,
  images,
  inPreview,
  previewContainerHeight,
  isSamplePage,
}) => {
  const {
    state: { wedding },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);

  const isDesktop = inPreview === 'DESKTOP' || (!inPreview && device?.isDesktop());
  const bgImg = isSamplePage
    ? wedding?.public_theme_v2?.sample_site_hero_image_url
    : (images && images[0]?.image_url) ||
      getWebsitePlaceholderAsset('SINGLE_IMAGE_SINGLE_PAGE', isDesktop, 1);

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  return (
    <BaseHero
      overrideHeight={previewContainerHeight}
      data-testid="SinglePageHeroSingleImage"
      ref={containerRef}
    >
      <HeroSized src={bgImg} alt="Hero Image" containerWidth={containerWidth} />
      {children}
    </BaseHero>
  );
};

export default SinglePageHeroSingleImage;
